import React, { useState, useEffect, useCallback, useRef } from "react";
import "./ShoppingCart.css";
import { Button, message, Spin, Rate } from "antd";
import { DeleteFilled, ArrowRightOutlined } from "@ant-design/icons";
import Instance from "../../AxiosConfig";
import { useNavigate } from "react-router-dom";
import cartImg from "../../Assets/SKImages/cart-img.png";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { addItems, removeItems } from "../../states/reducers/AddToCartSlice";
import _ from "lodash";
import { addCoupon, removeCoupon } from "../../states/reducers/CouponSlice";
import { MdCelebration } from "react-icons/md";
import { useDispatch } from "react-redux";
import INRrupee from "../Home/INRrupee";
import default_image from "../../Assets/SKImages/defailt-no-image.png";
import {PRODUCT_BASE_URL} from "../../globalConstant"

const ShoppingStep1 = ({ next, updateCartStatus }) => {
  const [cartItems, setCartItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [couponCode, setCouponCode] = useState("");
  const [isUpdating, setIsUpdating] = useState(false);
  const [isCouponApplied, setIsCouponApplied] = useState(false);

  const [couponDetails, setCouponDetails] = useState({
    couponDiscountedPrice: 0,
    totalAfterDiscount: 0,
  });
  const dispatch = useDispatch();
  const [expandedProducts, setExpandedProducts] = useState({});
  const [summary, setSummary] = useState({
    totalPrice: 0,
    discountedPrice: 0,
    discount: 0,
    deliveryFee: 200,
    discountPercentage: 0,
  });
  const navigate = useNavigate();
  const handleNextPage = () => {
    navigate("/shoppingstep-2");
  };
  const toggleExpand = (productId) => {
    setExpandedProducts((prev) => ({
      ...prev,
      [productId]: !prev[productId],
    }));
  };
  const handleRedirectPage = () => {
    navigate("/shop");
  };
  const groupCartItems = (items) => {
    return items.reduce((acc, item) => {
      if (!acc[item.productId]) {
        acc[item.productId] = [];
      }
      acc[item.productId].push(item);
      return acc;
    }, {});
  };
  const handleCardClick = (productId) => {
    console.log("Navigating to product:", productId);
    navigate(`/product-details/${productId}`);
  };
  const ProductCard = ({
    productId,
    image,
    name,
    rating,
    totalRatings,
    currentPrice,
    originalPrice,
    quantity: initialQuantity,
    showUnderline,
    onDelete,
    isSteelProduct,
    quantities,
    discountedPercentage,
    isExpanded,
    toggleExpand,
  }) => {
    const [localQuantities, setLocalQuantities] = useState([]);
    const [quantity, setQuantity] = useState(initialQuantity);
    const [isUpdating, setIsUpdating] = useState(false);
    const quantityTimeoutRef = useRef(null);

    // Sort and set localQuantities whenever quantities change
    useEffect(() => {
      if (isSteelProduct && quantities) {
        const sortedQuantities = quantities
          .slice()
          .sort((a, b) => a.steelSize.localeCompare(b.steelSize));
        setLocalQuantities(sortedQuantities);
      } else {
        setLocalQuantities(quantities || []);
      }
    }, [quantities, isSteelProduct]);

    const updateQuantity = useCallback(
      _.debounce(async (item, newQuantity) => {
        setIsUpdating(true);
        try {
          let sessionId = sessionStorage.getItem("sessionId");
          const data = isSteelProduct
            ? { quantity: newQuantity, steelSize: item.steelSize }
            : { quantity: newQuantity };
          // console.log("aaa",data)
          const response = await Instance.put(
            `/api/v1/cartItems/${productId}?sessionId=${sessionId}`,
            data,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("authToken")}`,
              },
            }
          );
          // console.log("update response:", response.data)
          if (response.status === 200) {
            message.success("Quantity updated successfully");
            fetchCartData();
          }
        } catch (error) {
          message.error(error?.response?.data?.message || "An error occurred");
        } finally {
          setIsUpdating(false);
        }
      }, 1700),
      [productId, isSteelProduct]
    );

    const handleQuantityChange = (e, item) => {
      const value = e.target.value;
      const numericValue = Number(value);

      if (!isNaN(numericValue)) {
        setLoading(true);

        setQuantity(numericValue);

        if (quantityTimeoutRef.current) {
          clearTimeout(quantityTimeoutRef.current);
        }
        let newQuantity = numericValue > 100000 ? 100000 : numericValue;

        if (newQuantity < 25) {
          quantityTimeoutRef.current = setTimeout(() => {
            setQuantity(25);
            setLocalQuantities((prevQuantities) =>
              prevQuantities.map((q) =>
                q.cartItemId === item.cartItemId
                  ? { ...q, quantity: 25 }
                  : q
              )
            );
            updateQuantity(item, 25);
          }, 2000);
        } else {
          if (isSteelProduct) {

            setLocalQuantities((prevQuantities) =>
              prevQuantities.map((q) =>
                q.cartItemId === item.cartItemId
                  ? { ...q, quantity: newQuantity }
                  : q
              )
            );
          }

          // Update the quantity immediately
          updateQuantity(item, newQuantity);
        }

        setLoading(false);
      } else {
        message.error("Please enter a valid number.");
      }
    };

    // Cleanup the timeout on component unmount
    useEffect(() => {
      return () => {
        if (quantityTimeoutRef.current) {
          clearTimeout(quantityTimeoutRef.current);
        }
      };
    }, []);



    const incrementQuantity = (item) => {
      const newQuantity = item.quantity + 1;
      if (newQuantity <= 100000) {
        handleQuantityChange({ target: { value: newQuantity } }, item);
      }
    };

    const decrementQuantity = (item) => {
      if (item.quantity > 1) {
        const newQuantity = item.quantity - 1;
        handleQuantityChange({ target: { value: newQuantity } }, item);
      }
    };

    const handleDelete = () => {
      onDelete(productId);
    };

    const calculateTotalDiscountedPrice = () => {
      if (isSteelProduct) {
        return localQuantities.reduce(
          (total, item) => total + item.discountedPrice,
          0
        );
      }
      return currentPrice;
    };

    const calculateDiscountPercentage = () => {
      if (isSteelProduct && localQuantities.length > 0) {
        const totalPrice = localQuantities.reduce(
          (total, item) => total + item.price,
          0
        );
        const totalDiscountedPrice = localQuantities.reduce(
          (total, item) => total + item.discountedPrice,
          0
        );
        return Math.floor(((totalPrice - totalDiscountedPrice) / totalPrice) * 100);
      }
      return discountedPercentage;
    };

    const calculateOriginalPrice = () => {
      if (isSteelProduct) {
        return localQuantities.reduce((total, item) => total + item.price, 0);
      }
      return originalPrice;
    };

    const handleFocus = (e, quantity) => {
      if (quantity === 1) {
        e.target.value = '';
      }
    };

    const handleBlur = (e, productId, quantity) => {
      if (e.target.value === '' || isNaN(parseInt(e.target.value))) {
        setQuantity(1);
        updateQuantity({ cartItemId: productId, quantity: 1 }, 1);
      }
    };

    return (
      <>
        <div
          className={`row left-main-cart ${showUnderline ? "underline" : ""}`}
        >
          <div className="col-md-3 ">
            <img
              src={image && image.trim() ? image : default_image}
              className="shop-img-cart"
              alt="Product Image"
              onClick={() => handleCardClick(productId)}
            />
          </div>
          <div className="col-md-6  shopping-cart-card-details">
            <h5>{name}</h5>
            <div className="d-flex gap-2 pt-1">
              <Rate
                disabled
                allowHalf
                value={rating}
                style={{
                  display: "flex",
                  justifyContent: "start",
                  paddingBottom: "5px",
                }}
              />
              <p style={{ fontSize: "12px", color: "#1b3a57" }}>{rating}</p>
              <p style={{ fontSize: "12px", color: "#1b3a57" }}>
                ({totalRatings})
              </p>
            </div>
            {!isSteelProduct && (
              <p style={{ paddingBottom: "5px" }}>Quantity : {quantity}</p>
            )}
            <span className="product-current-price">
              <INRrupee /> {calculateTotalDiscountedPrice()}
            </span>
            <span className="product-original-price">
              <INRrupee /> {calculateOriginalPrice()}
            </span>
            <span
              style={{
                backgroundColor: "#1b3a57",
                color: "#fff",
                padding: "5px",
                borderRadius: "38%",
                fontSize: "12px",
              }}
            >
              {calculateDiscountPercentage()}% off
            </span>
            <br />
            {!isSteelProduct && (
              <p style={{ fontSize: "12px", color: "gray", marginTop: "5px" }}>28% GST has been applied to the Cement</p>
            )}
            {isSteelProduct && (
              <p style={{ fontSize: "12px", color: "gray", marginTop: "5px" }}>18% GST has been applied to the Steel</p>
            )}
            {isSteelProduct && (
              <button
                className="view-more-button"
                style={{
                  backgroundColor: "#fff",
                  border: "none",
                  color: "#1b3a57",
                }}
                onClick={toggleExpand}
              >
                {isExpanded ? "View Less" : "View More"}
                {isExpanded ? <IoIosArrowUp /> : <IoIosArrowDown />}
              </button>
            )}
          </div>
          <div className="col-md-3  align-items-center justify-content-end">
            <DeleteFilled
              className="shoppingstep1-delete-icon"
              style={{ color: "red", fontSize: "20px" }}
              onClick={handleDelete}
            />
            {!isSteelProduct && (
              <div className="quantity-cart-selector d-flex justify-content-end">
                <>
                  <button
                    className="quantity-button"
                    onClick={() =>
                      decrementQuantity({ cartItemId: productId, quantity })
                    }
                  >
                    -
                  </button>
                  {isUpdating ? (
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginTop: '7px',
                        height: '100%',
                        width: '100%'
                      }}
                    >
                      <Spin size="small" />
                    </div>
                  ) : (
                    <input
                      type="text"
                      className="quantity-change-input"
                      value={quantity}
                      onFocus={(e) => handleFocus(e, quantity)}
                      onChange={(e) =>
                        handleQuantityChange(e, {
                          cartItemId: productId,
                          quantity,
                        })
                      }
                      onBlur={(e) =>
                        handleBlur(e, productId, quantity)}
                    />
                  )}
                  <button
                    className="quantity-button"
                    onClick={() =>
                      incrementQuantity({ cartItemId: productId, quantity })
                    }
                  >
                    +
                  </button>
                </>
              </div>
            )}
          </div>

          {isExpanded && isSteelProduct && (
            <div className="steel-product-details">
              <table className="table">
                <thead>
                  <tr>
                    <th>Steel Size</th>
                    <th>Quantity</th>
                    <th>Price</th>
                  </tr>
                </thead>
                <tbody>
                  {localQuantities.slice()
                    .sort((a, b) => {
                      const sizeA = parseFloat(a.steelSize.match(/\d+/));
                      const sizeB = parseFloat(b.steelSize.match(/\d+/));
                      return sizeA - sizeB;
                    }).map((item) => (
                      <tr key={item.cartItemId}>
                        <td>{item.steelSize}</td>
                        <td className="d-flex justify-content-center">
                          <div className="quantity-cart-selector-steel">
                            <button
                              className="quantity-button"
                              onClick={() => decrementQuantity(item)}
                            >
                              -
                            </button>
                            {isUpdating ? (
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  marginTop: '3px',
                                  height: '100%',
                                  width: '100%'
                                }}
                              >
                                <Spin size="small" />
                              </div>
                            ) : (
                              <input
                                type="text"
                                className="quantity-change-input"
                                value={item.quantity}
                                onFocus={(e) => handleFocus(e, item.quantity)}
                                onChange={(e) => handleQuantityChange(e, item)}
                                onBlur={(e) => handleBlur(e, item.productId, item.quantity)}

                              />
                            )}
                            <button
                              className="quantity-button"
                              onClick={() => incrementQuantity(item)}
                            >
                              +
                            </button>
                          </div>
                        </td>
                        <td><INRrupee />{item.discountedPrice}</td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </>
    );
  };

  const fetchCartData = async () => {
    // setLoading(true);
    try {
      let sessionId = sessionStorage.getItem("sessionId");
      const url = `/api/v1/cart/?sessionId=${sessionId}`;
      const response = await Instance.get(url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
      });
      if (response.status === 200) {
        console.log("sdfghjk", response);
        const groupedItems = groupCartItems(response.data.cartItems);
        console.log("grouped", groupedItems);
        setCartItems(groupedItems);
        setSummary({
          totalPrice: response.data.totalPrice,
          discountedPrice: response.data.totalDiscountedPrice,
          discount: response.data.discounte,
          discountPercentage: response.data.discountPercentage,
          couponType: response.data.couponType,
        });
        updateCartStatus(response.data.cartItems.length === 0);
        setIsCouponApplied(response.data.couponApplied);
        // if(isCouponApplied(true))
        setCouponCode(response.data.couponCode);
        setCouponDetails({
          couponDiscountedPrice: response.data.couponDiscountedPrice,
          totalAfterDiscount: response.data.totalAfterDiscount,
        });
        dispatch(addItems(response.data.cartItems))
      }
    } catch (error) {
      message.error(error?.response?.data?.message || "An error occurred");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCartData(dispatch);
  }, [dispatch]);

  const handleDeleteCartItem = async (productId) => {
    try {
      let sessionId = sessionStorage.getItem("sessionId");
      const response = await Instance.delete(
        `/api/v1/cartItems/${productId}?sessionId=${sessionId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
        }
      );
      if (response.status === 200) {
        message.success(response.data.message);
        dispatch(removeItems(productId))
        fetchCartData();
      }
    } catch (error) {
      message.error(error?.response?.data?.message || "An error occurred");
    }
  };

  const handleApplyCoupon = async () => {
    try {
      let sessionId = sessionStorage.getItem("sessionId");
      const authToken = localStorage.getItem("authToken");
      if (!authToken) {
        if (!sessionId) {
          sessionId = generateSessionId();
          sessionStorage.setItem("sessionId", sessionId);
        }
      } else {
        sessionId = "";
        sessionStorage.removeItem("sessionId");
      }
      const response = await Instance.put(
        `/api/v1/cart/applyCoupon?couponCode=${couponCode}${sessionId ? `&sessionId=${sessionId}` : ""
        }`,
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
        }
      );
      if (response.status === 200) {
        // setIsCouponApplied(true);
        message.success("Coupon applied successfully");
        console.log(response.data);
        fetchCartData();
      }
    } catch (error) {
      message.error(error?.response?.data?.message || "An error occurred");
      console.log(error.response.data);
    }
  };
  const generateSessionId = () => {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
      const r = (Math.random() * 16) | 0,
        v = c === "x" ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  };
  const handleRemoveCoupon = async () => {
    try {
      let sessionId = sessionStorage.getItem("sessionId");
      const response = await Instance.delete(
        `/api/v1/cart/removeCoupon?sessionId=${sessionId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
        }
      );
      if (response.status === 200) {
        message.success("coupon removed successfully");
        setIsCouponApplied(false);
        setCouponCode("");
      }
    } catch (error) {
      message.error(error?.response?.data?.message || "An error occurred");
      console.log(error.response);
    }
  };

  const handleCouponCodeChange = (e) => {
    const newCouponCode = e.target.value.toUpperCase();
    setCouponCode(newCouponCode);
    if (newCouponCode.trim() === "") {
      setIsCouponApplied(false);
    }
  };
  const getCartItemsArray = () => {
    return Object.values(cartItems).flat();
  };
  console.log("cartItems:", cartItems);
  console.log("Array.isArray(cartItems):", Array.isArray(cartItems));

  const handleCheckout = () => {
    message.config({
      duration: 10,
    });

    const itemsArray = getCartItemsArray();

    if (itemsArray.length === 0) {
      message.warning("Your cart is empty. Please add items to proceed.");
      return;
    }

    let errorMessage = "";
    let totalSteelQuantity = 0;
    let totalCementQuantity = 0;
    let hasSteelProduct = false;
    let hasCementProduct = false;

    itemsArray.forEach((item) => {
      if (item.steelProduct) {
        hasSteelProduct = true;
        totalSteelQuantity += item.quantity;
      } else {
        hasCementProduct = true;
        totalCementQuantity += item.quantity;
      }
    });

    if (hasSteelProduct && totalSteelQuantity < 150) {
      errorMessage += `Steel products must have a total quantity greater than 150kg. `;
    }

    if (hasCementProduct && totalCementQuantity < 25) {
      errorMessage += `Cement products must have a total quantity greater than 25 bags. `;
    }

    if (errorMessage) {
      message.error(errorMessage.trim());
      return;
    }

    const authToken = localStorage.getItem("authToken");
    if (authToken) {
      next();
    } else {
      navigate("/signin");
    }
  };

  return (
    <>
      {loading ? (
        <div className="spin-overlay my-5">
          <Spin spinning={loading} size="large" />
        </div>
      ) : (
        <div className="container shopping-step1-div mt-4 ">
          {Object.keys(cartItems).length === 0 ? (
            <div className="empty-cart-container container justify-content-center">
              <img src={cartImg} alt="" />
              <h3>Your cart is empty and sad :</h3>
              <p>Add something to make it happy!</p>
              <button className="continue-button" onClick={handleRedirectPage}>
                Continue Shopping
              </button>
            </div>
          ) : (
            <div className="row">
              <div className="col-lg-7  ">
                <div className="card  p-4 cart-option-product-div  ">
                  {Object.keys(cartItems).map((productId, index) => (
                    <ProductCard
                      key={index}
                      productId={productId}
                      image={`${PRODUCT_BASE_URL}${cartItems[productId][0].imageUrl}`}
                      name={cartItems[productId][0].title}
                      currentPrice={cartItems[productId][0].discountedPrice}
                      originalPrice={cartItems[productId][0].price}
                      quantities={cartItems[productId]}
                      onDelete={handleDeleteCartItem}
                      isSteelProduct={cartItems[productId][0].steelProduct}
                      steelSize={cartItems[productId][0].steelSize}
                      rating={cartItems[productId][0].numRatings}
                      totalRatings={cartItems[productId][0].totalRatings}
                      quantity={cartItems[productId][0].quantity}
                      discountedPercentage={
                        cartItems[productId][0].discountPercentage
                      }
                      isExpanded={expandedProducts[productId]}
                      toggleExpand={() => toggleExpand(productId)}
                    />
                  ))}
                </div>
              </div>
              <div
                className="col-lg-5"
              >
                <div className={`card  p-4 order-summary-card ${isCouponApplied ? "expanded" : ""
                  }`}>
                  <h5 className="text-align-left d-flex">Order Summary</h5>
                  <div className="d-flex justify-content-between sub-total-price">
                    <div className="sub-discount">
                      <p>Sub Total</p>
                      <p>Discount (-{summary.discountPercentage}%)</p>
                    </div>
                    <div className="total-price">
                      <p><INRrupee /> {(summary.totalPrice ?? 0).toFixed(2)}</p>
                      <p><INRrupee /> {(summary.discount ?? 0).toFixed(2)}</p>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between pt-2 total-price">
                    <div>
                      <p>Total</p>
                    </div>
                    <div>
                      <p><INRrupee /> {(summary.discountedPrice ?? 0).toFixed(2)}</p>
                    </div>
                  </div>
                  <div className="justify-content-between d-flex cupon-button mb-2 mt-2">
                    <div>
                      <input
                        type="text"
                        value={couponCode}
                        onChange={handleCouponCodeChange}
                        placeholder="Enter Coupon Code"
                        className="coupon-input"
                        disabled={isCouponApplied}
                      />
                    </div>
                    {isCouponApplied ? (
                      <button
                        className="faq-get-in-touch-button"
                        onClick={handleRemoveCoupon}
                      >
                        Remove
                      </button>
                    ) : (
                      <button
                        className="faq-get-in-touch-button"
                        onClick={handleApplyCoupon}
                      >
                        Apply
                      </button>
                    )}
                  </div>
                  {isCouponApplied && (
                    <div>
                      <h5
                        className="d-flex justify-content-start pt-2 pb-2"
                        style={{ color: "#1b3a57" }}
                      >
                        Coupon Applied &nbsp;{" "}
                      </h5>
                      <div className="d-flex justify-content-between">
                        <div className="sub-discount">
                          <p>Total Amount</p>
                          <p style={{ color: "#54C104" }}>Coupon Savings</p>
                        </div>
                        <div className="total-price">
                          <p><INRrupee /> {(summary.discountedPrice ?? 0).toFixed(2)}</p>
                          <p style={{ marginLeft: "-10px", color: "#54C104" }}>
                            {summary.couponType === "FREE_SHIPPING"
                              ? "Free Shipping"
                              : `-₹ ${(
                                couponDetails.couponDiscountedPrice ?? 0
                              ).toFixed(2)}`}
                          </p>
                        </div>
                      </div>
                      <div className="straight-line-border my-1"></div>
                      <div className="d-flex justify-content-between">
                        <div className="sub-discount">
                          <p style={{ fontWeight: "bold" }}>Amount Payable</p>
                        </div>
                        <div className="total-price">
                          <p>
                            ₹ {(couponDetails.totalAfterDiscount ?? 0).toFixed(2)}
                          </p>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="mt-3 shopping-step1-gotocheckout">
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="contact-button d-flex justify-content-center align-items-center "
                      onClick={handleCheckout}
                    >
                      Go to Checkout
                      <ArrowRightOutlined />
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default ShoppingStep1;
