import React, { useState, useEffect } from "react";
import Instance from "../../AxiosConfig";
import { message, Spin } from "antd";
import { Rate, Pagination, Slider, Menu } from "antd";
import default_image from "../../Assets/SKImages/defailt-no-image.png";

import {
  SearchOutlined,
  ShoppingCartOutlined,
  CaretUpOutlined,
  CaretDownOutlined,
  HeartOutlined,
  HeartFilled,
} from "@ant-design/icons";
import "./Shop.css";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { DownOutlined } from "@ant-design/icons";
import { Dropdown, Space } from "antd";
import INRrupee from "../Home/INRrupee";
import { addWishlist, addWishlists, removeWishlist } from "../../states/reducers/wishlistSlice";
import { addItem, addItems } from "../../states/reducers/AddToCartSlice";
import { useDispatch } from "react-redux";
import {PRODUCT_BASE_URL} from "../../globalConstant"

const items = [
  {
    label: <h6>Price Low High</h6>,
    key: "price_low_high",
    text: "Price Low High", // Added text for display
  },
  {
    type: "divider",
  },
  {
    label: <h6>Price High Low</h6>,
    key: "price_high_low",
    text: "Price High Low", // Added text for display
  },
  {
    type: "divider",
  },
  {
    label: <h6>Popularity</h6>,
    key: "popularity",
    text: "Popularity", // Added text for display
  },
];

const ShopProduct = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const navigate = useNavigate();
  const handleCardClick = (productId) => {
    navigate(`/product-details/${productId}`);
  };
  const productType = queryParams.get("type") || "null";
  const [position] = useState("start");
  const [minPrice, setMinPrice] = useState(0);
  const [maxPrice, setMaxPrice] = useState(5000);
  const [priceRangeVisible, setPriceRangeVisible] = useState(true);
  const [categoriesVisible, setCategoriesVisible] = useState(true);
  const [brandsVisible, setBrandsVisible] = useState(true);
  const [ratingsVisible, setRatingsVisible] = useState(true);
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [pageSize, setPageSize] = useState(9);
  const [categories, setCategories] = useState([]);
  const [brands, setBrands] = useState([]);
  const [selectedSort, setSelectedSort] = useState({ key: "", text: "Sort By" });
  const [sortingApplied, setSortingApplied] = useState(false);
  const dispatch = useDispatch()
  const handleMenuClick = (e) => {
    const selectedItem = items.find((item) => item.key === e.key);
    if (selectedItem) {
      setSelectedSort({ key: e.key, text: selectedItem.text });
      setSortingApplied(true);
    }
    fetchProductsFilters(currentPage, pageSize, e.key); // Pass sorting key
  };
  const handleVisibleChange = (flag) => {
    setDropdownVisible(flag);
  };
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedBrands, setSelectedBrands] = useState([]);
  const [minRating, setMinRating] = useState("");
  const [wishlistData, setWishlistData] = useState([]);
  const [filtersApplied, setFiltersApplied] = useState(false);
  const [filteredBrands, setFilteredBrands] = useState([]);


  const [selectedRatings, setSelectedRatings] = useState([]);
  const onChange = (value) => {
    setMinPrice(value[0]);
    setMaxPrice(value[1]);
  };
  const togglePriceRange = () => {
    setPriceRangeVisible(!priceRangeVisible);
  };
  const toggleCategories = () => {
    setCategoriesVisible(!categoriesVisible);
  };
  const toggleBrands = () => {
    setBrandsVisible(!brandsVisible);
  };
  const toggleRatings = () => {
    setRatingsVisible(!ratingsVisible);
  };

  const [liked, setLiked] = useState([]);
  const toggleLike = (index, productId) => {
    const newLiked = [...liked];
    newLiked[index] = !newLiked[index];
    setLiked(newLiked);
    if (newLiked[index]) {
      addToWishlist(productId);
    }
  };

  const fetchWishlistData = async () => {
    try {
      let sessionId = sessionStorage.getItem("sessionId");
      const url = `/api/v1/wishlist/?sessionId=${sessionId}`;
      const response = await Instance.get(url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
      });
      if (response.status === 200) {
        setWishlistData(response.data);
        dispatch(addWishlists(response.data))
      }
    } catch (error) {
      console.error(error?.response?.data?.message || "An error occurred");
    }
  };
  useEffect(() => {
    fetchWishlistData(dispatch);
  }, [dispatch]);

  const addToWishlist = async (productId) => {
    try {
      let sessionId = sessionStorage.getItem("sessionId");
      const authToken = localStorage.getItem("authToken");
      if (!authToken) {
        if (!sessionId) {
          sessionId = generateSessionId();
          sessionStorage.setItem("sessionId", sessionId);
        }
      } else {
        sessionId = "";
        sessionStorage.removeItem("sessionId");
      }
      const url = `/api/v1/wishlist/products/${productId}${sessionId ? `?sessionId=${sessionId}` : ""
        }`;
      const response = await Instance.post(
        url,
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
        }
      );
      if (response.status === 200) {
        message.success(response.data.message);
        dispatch(addWishlist(response.data))
        fetchWishlistData();
      }
    } catch (error) {
      message.error(
        error?.response?.data?.message ||
        "An error occurred while adding to wishlist"
      );
    }
  };

  const fetchProductsBasedOnFilters = async (page, size, sortBy = null) => {
    setLoading(true);
    try {
      let url = "";
      const categories = selectedCategories.join(",");
      const brands = selectedBrands.join(",");

      const ratings = selectedRatings.join(",")
      if (filtersApplied || sortingApplied) {
        url = `/api/v1/products/search-and-sort?categoryTitle=${categories}&minPrice=${minPrice}&maxPrice=${maxPrice}&brands=${brands}&ratings=${ratings}&pageNumber=${page - 1}&pageSize=${size}&sortBy=${sortBy}&sortDir=desc`;
      }
      else {
        if (productType && productType !== "null" && productType !== "") {
          url = `/api/v1/products/category/pagination/${productType}?pageNumber=${page - 1}&pageSize=${size}&sortBy=productId&sortDir=desc`;
        } else {
          url = `/api/v1/products/?pageNumber=${page - 1}&pageSize=${size}&sortBy=productId&sortDir=desc`;
        }
      }



      const response = await Instance.get(url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
      });

      if (response.status === 200) {
        setProducts(response.data.content);
        setTotalItems(response.data.totalElements);
        console.log(response.data)
      }
    } catch (error) {
      // message.error(error?.response?.data?.message || "An error occurred");
    } finally {
      setLoading(false);
    }
  };


  const fetchProductsFilters = async (page, size, sortBy = null) => {
    setLoading(true);
    try {
      let url = "";
      const categories = selectedCategories.join(",");
      const brands = selectedBrands.join(",");

      const ratings = selectedRatings.join(",")
      if (filtersApplied || sortingApplied) {
        url = `/api/v1/products/search-and-sort?categoryTitle=${categories}&minPrice=${minPrice}&maxPrice=${maxPrice}&brands=${brands}&ratings=${ratings}&pageNumber=${page - 1}&pageSize=${size}&sortBy=${sortBy}&sortDir=desc`;
      }
      const response = await Instance.get(url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
      });

      console.log(url)
      if (response.status === 200) {
        setProducts(response.data.content);
        setTotalItems(response.data.totalElements);
      }
    } catch (error) {
      // message.error(error?.response?.data?.message || "An error occurred");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchProductsBasedOnFilters(currentPage, pageSize, selectedSort.key);
  }, [currentPage, pageSize, filtersApplied, productType, sortingApplied, selectedSort]);




  const handleCategoryChange = (category) => {
    setSelectedBrands([]);
    if (selectedCategories.includes(category)) {
      setSelectedCategories([]);
      setFilteredBrands(brands);
    } else {
      setSelectedCategories([category]);
      filterBrandsByCategory(category);
    }
  };


  const filterBrandsByCategory = (category) => {
    const filtered = brands.filter((brand) => brand.categories === category);
    setFilteredBrands(filtered);
  };

  const handleBrandChange = (brand) => {
    setSelectedBrands((prev) =>
      prev.includes(brand) ? prev.filter((b) => b !== brand) : [...prev, brand]
    );
  };

  const handleRatingChange = (rating) => {

    if (selectedRatings.includes(rating)) {
      setSelectedRatings(selectedRatings.filter((r) => r !== rating));
    } else {
      setSelectedRatings([...selectedRatings, rating]);
    }
  };

  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(pageSize);
    fetchProductsBasedOnFilters(page, pageSize, selectedSort);
    fetchProductsFilters(page, pageSize, selectedSort)
    const scrollPosition = window.innerWidth < 768 ? 900 : 450;

    window.scrollTo({
      top: scrollPosition,
      behavior: 'smooth'
    });

  };

  const handleMinPriceChange = (e) => {
    const enteredValue = Number(e.target.value.replace(/[^0-9]/g, ""));
    
    const adjustedValue = enteredValue > 10000000 ? 10000000 : enteredValue;
  
    setMinPrice(Math.min(adjustedValue, maxPrice - 1));
  };

  const handleMaxPriceChange = (e) => {
    const enteredValue = Number(e.target.value.replace(/[^0-9]/g, ""));
    
    const adjustedValue = enteredValue > 10000000 ? 10000000 : enteredValue;
    
    setMaxPrice(Math.max(adjustedValue, minPrice + 1));
  };

  const handleFocus = (e, maxPrice) => {
    if (maxPrice === 1) {
      e.target.value = '';
    } else {
      e.target.value = e.target.value.replace(/[₹,]/g, '');
    }
  };

  const handleBlur = (e, minPrice) => {
    const value = Number(e.target.value.replace(/[^0-9]/g, ""));
    if (!value || isNaN(value)) {
      setMaxPrice(minPrice + 1);
    } else {
      setMaxPrice(value);
    }
  };
  const sliderMax = maxPrice > 5000 ? maxPrice : 5000;

  const addToCart = async (productId, cartItemDtos = []) => {
    try {
      let sessionId = sessionStorage.getItem("sessionId");
      const authToken = localStorage.getItem("authToken");

      if (!authToken) {
        if (!sessionId) {
          sessionId = generateSessionId();
          sessionStorage.setItem("sessionId", sessionId);
        }
      } else {
        sessionId = "";
        sessionStorage.removeItem("sessionId");
      }

      const url = `api/v1/cart/add/${productId}${sessionId ? `?sessionId=${sessionId}` : ""}`;
      const response = await Instance.post(url, cartItemDtos, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      if (response.status === 200 || response.status === 201) {
        dispatch(addItem(response.data));
        const successMessage = response.data.message;
        message.success(successMessage);
        fetchCartCount()
      }
    } catch (error) {
      console.error("Error adding to cart:", error);
      message.error(
        error?.response?.data?.message || "An error occurred while adding to cart"
      );
    }
  };
  const fetchCartCount = async () => {
    try {
      let sessionId = sessionStorage.getItem("sessionId");
      const response = await Instance.get(
        `/api/v1/cart/?sessionId=${sessionId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
        }
      );
      if (response.status === 200) {
        dispatch(addItems(response.data.cartItems))
      }

    } catch (error) {
      console.error("Error fetching cart count:", error);
    }
  };

  useEffect(() => {
    fetchCartCount(dispatch);
  }, [dispatch]);
  const removeFromWishlist = async (productId) => {
    try {
      let sessionId = sessionStorage.getItem("sessionId");
      const url = `/api/v1/wishlist/product/${productId}?sessionId=${sessionId}`;
      const response = await Instance.delete(url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
      });
      if (response.status === 200) {
        message.success(response?.data?.message);
        dispatch(removeWishlist(productId));
        fetchWishlistData();
      }
    } catch (error) {
      message.error(error?.response?.data?.message || "An error occurred");
    }
  };
  const generateSessionId = () => {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
      const r = (Math.random() * 16) | 0,
        v = c === "x" ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  };
  const getBrands = async () => {
    try {
      const url = "/api/v1/brand/";
      const response = await Instance.get(url);
      if (response.status === 200) {
        console.log("brands", response.data)
        setBrands(response.data);
        setFilteredBrands(response.data);
      } else {
        setBrands([]);
        setFilteredBrands([]);
      }
    } catch (error) {
      console.error("Failed to fetch brands:", error);
      setBrands([]);
      setFilteredBrands([]);
    }
  };

  useEffect(() => {
    getBrands();
    getProductCategory();
  }, []);

  const getProductCategory = async () => {
    try {
      const url = `/api/v1/categories/`;
      const response = await Instance.get(url);
      if (response.status === 200) {
        setCategories(response.data);
      }
    } catch (error) {
      console.error("Failed to fetch categories:", error);
    }
  };

  const renderCard = (card, index) => {
    const isLiked = wishlistData.some(
      (item) => item.productId === card.productId
    );

    const toggleLike = (e) => {
      e.stopPropagation();
      if (isLiked) {
        removeFromWishlist(card.productId);
      } else {
        addToWishlist(card.productId);
      }
    };
    return (
      <div
        key={index}
        className="products-card-main"
        // style={{ width: "260px" }}
        onClick={() => handleCardClick(card.productId)}
      >
        <div className="Heart-outlined" onClick={toggleLike}>
          {isLiked ? (
            <HeartFilled style={{ color: "red" }} />
          ) : (
            <HeartOutlined />
          )}
        </div>
        <div>
          <img
            src={
              card.imageUrl && card.imageUrl.trim() !== ""
                ? `${PRODUCT_BASE_URL}${card.imageUrl}`
                : default_image
            }
            alt={card.title || "Default Image"}
            style={{ width: "200px", height: "200px" }}
          />
        </div>
        <div className="products-card-body">
          <Rate
            disabled
            allowHalf
            value={card.numRatings}
            style={{
              display: "flex",
              justifyContent: "start",
              paddingTop: "20px",
            }}
          />
          <h2>{card.title}</h2>
          <h3>{card.description}</h3>
          <div className="product-price d-flex gap-2 align-items-center">
            <h3 className="price"><INRrupee />{card.discountedPrice}</h3>
            <span className="original-price"><INRrupee />{card.price}</span>
            <span className="home-product-detail-discount">
              {card?.discountPercentage}% off
            </span>
          </div>
          <p className="product-brand">
            <span>Brand:</span> {card.brand}
          </p>
          <button
            className="add-to-cart-button mt-3 "
            onClick={(e) => handleAddToCart(e, card)}
          >
            <ShoppingCartOutlined className="pe-2" />
            Add To Cart
          </button>
        </div>
      </div>
    );
  };
  const handleAddToCart = (e, product) => {
    e.stopPropagation();
    if (product.categoryTitle.toLowerCase() === "steel") {
      handleCardClick(product.productId);
    } else {
      addToCart(product.productId);
    }
  };
  const handleApplyFilter = () => {
    if (window.innerWidth > 768) {

      window.scrollTo({
        top: 500,
        behavior: 'smooth'
      });
    } else {

      window.scrollTo({
        top: window.pageYOffset + 300,
        behavior: 'smooth'
      });
    }

    setCurrentPage(1);
    setFiltersApplied(true);
    fetchProductsFilters(currentPage, pageSize);
  };
  return (
    <div className="container shop-container">
      <div className="row shop-product-row">
        <div className="col-lg-3">
          <div className="sticky-product-sidebar">
            <div className="d-flex justify-content-between">
              <h4 className="form-label d-flex justify-content-start">
                Price Range
              </h4>
              <div onClick={togglePriceRange} style={{ cursor: "pointer" }}>
                {priceRangeVisible ? <CaretUpOutlined /> : <CaretDownOutlined />}
              </div>
            </div>
            {priceRangeVisible && (
              <div>
                <Slider
                  range
                  step={100}
                  min={0}
                  max={sliderMax}
                  value={[minPrice, maxPrice]}
                  onChange={onChange}
                  className="price-slider"
                />
                <div className="price-inputs d-flex justify-content-between">
                  <input
                    type="text"
                    value={`₹${minPrice}`}
                    onChange={handleMinPriceChange}
                    className="price-input"
                  />
                  <span> - </span>
                  <input
                    type="text"
                    value={`₹${maxPrice}`}
                    onFocus={(e) => handleFocus(e, maxPrice)}
                    onChange={handleMaxPriceChange}
                    onBlur={(e) => handleBlur(e, minPrice)}
                    className="price-input"
                  />
                </div>
              </div>
            )}
            <div className="filter-section">
              <div className="d-flex justify-content-between">
                <h4>Categories</h4>
                <div onClick={toggleCategories} style={{ cursor: "pointer" }}>
                  {categoriesVisible ? (
                    <CaretUpOutlined />
                  ) : (
                    <CaretDownOutlined />
                  )}
                </div>
              </div>
              {categoriesVisible && (
                <ul>
                  {loading ? (
                    <p>Loading...</p>
                  ) : categories.length > 0 ? (
                    categories.map((category) => (
                      <li key={category.categoryId}>
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id={category.categoryId}
                            checked={selectedCategories.includes(category.categoryTitle)}
                            onChange={() => handleCategoryChange(category.categoryTitle)}
                          />
                          <label className="form-check-label" htmlFor={category.categoryId}>
                            {category.categoryTitle}
                          </label>
                        </div>
                      </li>
                    ))
                  ) : !loading && categories.length === 0 ? (
                    <li>No categories available..!</li>
                  ) : null}
                </ul>
              )}
            </div>
            <div className="filter-section">
              <div className="d-flex justify-content-between">
                <h4>Brands</h4>
                <div onClick={toggleBrands} style={{ cursor: "pointer" }}>
                  {brandsVisible ? <CaretUpOutlined /> : <CaretDownOutlined />}
                </div>
              </div>
              {brandsVisible && (
                <ul>
                  {loading ? (
                    <p>Loading...</p>
                  ) : filteredBrands.length > 0 ? (
                    filteredBrands.map((brand) => (
                      <li key={brand.brandId}>
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id={brand.brandId}
                            onChange={() => handleBrandChange(brand.brandName)}
                            checked={selectedBrands.includes(brand.brandName)}
                          />
                          <label className="form-check-label" htmlFor={brand.brandId}>
                            {brand.brandName}
                          </label>
                        </div>
                      </li>
                    ))
                  ) : (
                    <li>No brands available..!</li>
                  )}
                </ul>
              )}
            </div>

            <div className="filter-section">
              <div className="d-flex justify-content-between">
                <h4>Rating</h4>
                <div onClick={toggleRatings} style={{ cursor: "pointer" }}>
                  {ratingsVisible ? <CaretUpOutlined /> : <CaretDownOutlined />}
                </div>
              </div>
              {ratingsVisible && (
                <ul>
                  {[5, 4, 3, 2, 1].map((rating) => (
                    <li key={rating}>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          checked={selectedRatings.includes(rating)}
                          onChange={() => handleRatingChange(rating)}
                        // onChange={() => handleRatingChange(rating)}
                        />
                      </div>
                      <Rate disabled defaultValue={rating} />
                      {rating < 5 && " & UP"}
                    </li>
                  ))}
                </ul>
              )}
            </div>

            <button className="faq-get-in-touch-button" onClick={handleApplyFilter}>
              Apply Filter
            </button>
          </div>
        </div>
        <div className="col-lg-9">
          <Spin spinning={loading} size="large" className="centered-spinner">
            <div className="d-flex filter-buttons-container justify-content-end">
              <div className="d-flex search-buttons-container gap-4 justify-content-end">
                <Dropdown
                  menu={{
                    items,
                    onClick: handleMenuClick,
                  }}
                  trigger={["click"]}
                >
                  <a
                    onClick={(e) => e.preventDefault()}
                    className="dropdown-trigger"
                  >
                    <Space>
                      {selectedSort.text} <DownOutlined />
                    </Space>
                  </a>
                </Dropdown>
              </div>
            </div>
            <div className="products-container gap-4">
              {!loading && products.length === 0 ? (
                <div className="no-products-found">
                  <h2>No products found</h2>
                  <p>
                    Try adjusting your search or filters to find what you're
                    looking for.
                  </p>
                </div>
              ) : (
                <>
                  <div className="products-card-container">
                    {products.map(renderCard)}

                  </div>

                  <Pagination
                    current={currentPage}
                    pageSize={pageSize}
                    total={totalItems}
                    onChange={handlePageChange}
                    className="shop-pagination"
                    showSizeChanger={false}
                  />
                </>
              )}
            </div>
            {/* <div className="products-container gap-4">
              <div className="products-card-container">
                {products.map(renderCard)}
              </div>
              <Pagination
                current={currentPage}
                pageSize={pageSize}
                total={totalItems}
                onChange={handlePageChange}
                className="shop-pagination"
                showSizeChanger={false}
              />
            </div> */}
          </Spin>
        </div>
      </div>

    </div>
  );
};

export default ShopProduct;
