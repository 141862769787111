import React, { useState, useEffect } from "react";
import { Menu, Divider, Spin, Rate, message, Popconfirm, Dropdown } from "antd";
import "./MyOrders.css";
import myorders from "../../../Assets/SKImages/myorders.png";
import { useNavigate } from "react-router-dom";
import Instance from "../../../AxiosConfig";
import cartImg from "../../../Assets/SKImages/cart-img.png";
import INRrupee from "../../Home/INRrupee";
import { HiDotsVertical } from "react-icons/hi";
import {PRODUCT_BASE_URL} from "../../../globalConstant"

const items = [
  {
    label: "Active",
    key: "active",
  },
  {
    label: "Cancelled",
    key: "cancelled",
  },
  {
    label: "Completed",
    key: "completed",
  },
];

const OrdersPage = () => {
  const [current, setCurrent] = useState("active");
  const [loading, setLoading] = useState(true);
  const [activeOrders, setActiveOrders] = useState([]);
  const [cancelledOrders, setCancelledOrders] = useState([]);
  const [completedOrders, setCompletedOrders] = useState([]);
  const navigate = useNavigate();
  const [showActions, setShowActions] = useState(false);

  const menu = (order) => (
    <Menu>
      <Menu.Item key="3">
        <Popconfirm
          title="Are you sure you want to cancel this order?"
          onConfirm={() => handleCancel(order.orderId)}
          onCancel={() => message.info('Order cancellation aborted')}
          okText="Yes"
          cancelText="No"
        >
          <span>Cancel Order</span>
        </Popconfirm>
      </Menu.Item>
    </Menu>
  );


  const onClick = (e) => {
    setCurrent(e.key);
  };

  const handleDetails = (order) => {
    navigate("/profile/my-orders/order-details", { state: { order } });
  };
  const handleRedirect = (orderId) => {
    navigate(`/payment-page/${orderId}`);
  }

  const fetchOrdersData = async () => {
    setLoading(true);
    try {
      const url = "/api/v1/orders/user";
      const response = await Instance.get(url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
      });
      if (response.status === 200 || response.status === 201) {
        console.log("orders data fetched successfully:", response.data);
        const sortedOrders = sortOrdersByDate(response.data);
        categorizeOrders(sortedOrders);
      }
    } catch (error) {
      console.log(error.response);
    } finally {
      setLoading(false);
    }
  };
  const sortOrdersByDate = (orders) => {
    return orders.sort((a, b) => new Date(b.createdDate) - new Date(a.createdDate));
  };
  const categorizeOrders = (orders) => {
    const active = [];
    const cancelled = [];
    const completed = [];
    orders.forEach((order) => {
      if (
        order.orderStatus === "SHIPPED" ||
        order.orderStatus === "PENDING" ||
        order.orderStatus === "ORDER_PLACED" ||
        order.orderStatus === "IN_PROGRESS"
      ) {
        active.push(order);
      } else if (order.orderStatus === "CANCELLED") {
        cancelled.push(order);
      } else if (order.orderStatus === "DELIVERED") {
        completed.push(order);
      }
    });
    setActiveOrders(active);
    setCancelledOrders(cancelled);
    setCompletedOrders(completed);
    setLoading(false);
  };

  useEffect(() => {
    fetchOrdersData();
  }, []);



  const handleCancel = async (orderId) => {
    try {
      const response = await Instance.put(`/api/v1/orders/${orderId}/cancel`, {}, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
      });

      if (response.status === 200) {
        message.success("Order canceled successfully");
        fetchOrdersData();
      }
    } catch (error) {
      console.error("Error canceling order:", error);
      message.error(error?.response?.data?.message || "An error occurred while canceling the order");
    }
  };


  const groupItemsByProductId = (items) => {
    return items.reduce((acc, item) => {
      if (!acc[item.productId]) {
        acc[item.productId] = {
          ...item,
          quantity: 0,
          totalRatings: item.totalRatings,
          imageData: item?.imageData,
          title: item.title,
          discountedPrice: 0,
          price: 0,
          discountedPercentage: item.discountPercentage
        };
      }
      acc[item.productId].quantity += item.quantity;
      acc[item.productId].discountedPrice += item.discountedPrice;
      acc[item.productId].price += item.price;

      return acc;
    }, {});
  };

  const renderOrders = (orders) => {
    if (orders.length === 0) {
      return (
        <div className="empty-cart-container container justify-content-center mt-5">
          <img src={cartImg} style={{ width: "150px", height: "150px", marginTop: "20px" }} alt="" />

          <p style={{ textAlign: "center", marginTop: "30px", fontWeight: "bold" }}>You don't have any orders.</p>
        </div>
      )
    }

    return orders.map((order) => {
      const groupedItems = groupItemsByProductId(order.orderItems);
      const firstItem = Object.values(groupedItems)[0];

      return (
        <div className="order-card mt-3" key={order.orderId}>
          {/* <div className="d-flex justify-content-between order-header myOrders-details-div">
            <div className="order-time">
              <p><span style={{ color: "#807D7E" }}>Order no:</span> #{order?.orderId}</p>
              <p ><span style={{ color: "#807D7E" }}>Order Date:</span> {order?.orderDate ? order?.orderDate : "NA"}</p>
              <p ><span style={{ color: "#807D7E" }}>Order Status: </span>{order?.orderStatus ? order?.orderStatus : "NA"}</p>
              <p>
                <span style={{ color: "#807D7E" }}>Payment Method:</span>{" "}
                {order.paymentDetails.paymentMethod ? order.paymentDetails.paymentMethod : "NA"}
              </p>
            </div>
            <div className="order-cards">
              <p>
                <span style={{ color: "#807D7E" }}>Payment Type:</span>{" "}&nbsp;
                {order?.paymentType ? order.paymentType : "NA"}
              </p>
              <p>
                <span style={{ color: "#807D7E" }}>Payment Status:</span>{" "}&nbsp;
                {order?.paymentDetails.paymentStatus ? order?.paymentDetails.paymentStatus : "NA"}
              </p>
              <p>
                <span style={{ color: "#807D7E" }}>Total Amount:</span>&nbsp;
                <INRrupee />{order.couponApplied
                  ? ((order?.totalAfterDiscount || 0) + (order?.deliveryFee || 0)).toFixed(2)
                  : ((order?.totalDiscountedPrice || 0) + (order?.deliveryFee || 0)).toFixed(2)}
              </p>
              {(order?.paymentType === "COD" || order?.paymentType === "bankTransfer" || order?.paymentType === "upi") && (
                <p>
                  <span style={{ color: "#807D7E" }}>Amount Paid:</span>{" "}&nbsp;
                  <INRrupee />
                  {
                    order?.paymentDetails?.paymentStatus === "COMPLETED"
                      ? order?.couponApplied === true
                        ? ((order?.totalAfterDiscount || 0) + (order?.deliveryFee || 0)).toFixed(2) // Coupon applied
                        : ((order?.totalDiscountedPrice || 0) + (order?.deliveryFee || 0)).toFixed(2) // Coupon not applied
                      : (order?.paymentDetails?.amountReceived || 0).toFixed(2) // Payment not completed
                  }
                </p>
              )}
            </div>
          </div> */}

          <>
            <div className="myOrders-details-div mb-4">
              <div className="d-flex justify-content-between order-header">
                <div className="order-time d-flex gap-2">
                  <div className="ordersPage-div-p">
                    <p><span style={{ color: "#807D7E" }}>Order no</span></p>
                    <p ><span style={{ color: "#807D7E" }}>Order Date</span> </p>
                    <p ><span style={{ color: "#807D7E" }}>Order Status </span></p>
                    <p>
                      <span style={{ color: "#807D7E" }}>Payment Method</span>{" "}

                    </p>
                  </div>
                  <div className="ordersPage-div-p">
                    <p>:</p>
                    <p>:</p>
                    <p>:</p>
                    <p>:</p>
                  </div>
                  <div className="ordersPage-div-p">
                    <p> #{order?.orderId}</p>
                    <p>{order?.orderDate ? order?.orderDate : "NA"}</p>
                    <p>{order?.orderStatus ? order?.orderStatus : "NA"}</p>
                    <p>{order.paymentDetails.paymentMethod ? order.paymentDetails.paymentMethod : "NA"}</p>
                  </div>
                </div>
                {/* <div className="order-cards">
              <p>
                <span style={{ color: "#807D7E" }}>Payment Type:</span>{" "}&nbsp;
                {order?.paymentType ? order.paymentType : "NA"}
              </p>
              <p>
                <span style={{ color: "#807D7E" }}>Payment Status:</span>{" "}&nbsp;
                {order?.paymentDetails.paymentStatus ? order?.paymentDetails.paymentStatus : "NA"}
              </p>
              <p>
                <span style={{ color: "#807D7E" }}>Total Amount:</span>&nbsp;
                <INRrupee />{order.couponApplied
                  ? ((order?.totalAfterDiscount || 0) + (order?.deliveryFee || 0)).toFixed(2)
                  : ((order?.totalDiscountedPrice || 0) + (order?.deliveryFee || 0)).toFixed(2)}
              </p>
              {(order?.paymentType === "COD" || order?.paymentType === "bankTransfer" || order?.paymentType === "upi") && (
                <p>
                  <span style={{ color: "#807D7E" }}>Amount Paid:</span>{" "}&nbsp;
                  <INRrupee />
                  {
                    order?.paymentDetails?.paymentStatus === "COMPLETED"
                      ? order?.couponApplied === true
                        ? ((order?.totalAfterDiscount || 0) + (order?.deliveryFee || 0)).toFixed(2) // Coupon applied
                        : ((order?.totalDiscountedPrice || 0) + (order?.deliveryFee || 0)).toFixed(2) // Coupon not applied
                      : (order?.paymentDetails?.amountReceived || 0).toFixed(2) // Payment not completed
                  }
                </p>
              )}
             </div> */}
                <div className="order-cards d-flex gap-2">
                  <div className="ordersPage-div-p">
                    <p>
                      <span style={{ color: "#807D7E" }}>Payment Type</span>{" "}&nbsp;

                    </p>
                    <p>
                      <span style={{ color: "#807D7E" }}>Payment Status</span>{" "}&nbsp;

                    </p>
                    <p>
                      <span style={{ color: "#807D7E" }}>Total Amount</span>&nbsp;

                    </p>
                    {/* {(order?.paymentType === "COD" || order?.paymentType === "bankTransfer" || order?.paymentType === "upi") && ( */}
                    <p>
                      <span style={{ color: "#807D7E" }}>Amount Paid</span>{" "}&nbsp;

                    </p>
                    {/* )} */}
                    {
                      !(
                        order?.paymentDetails?.paymentStatus === "COMPLETED" || order?.orderStatus === "CANCELLED" ||
                        (order?.paymentDetails?.paymentMethod === null ||
                          order?.paymentDetails?.paymentMethod === "NA" ||
                          order?.paymentDetails?.paymentMethod === "") &&
                        (order?.paymentType === null ||
                          order?.paymentType === "NA" ||
                          order?.paymentType === "")
                      ) && (
                        <p>
                          <span style={{ color: "#807D7E" }}>Balance Amount </span>

                        </p>
                      )}
                  </div>
                  <div className="ordersPage-div-p ordersDetails-mobile2">
                    <p>:</p>
                    <p>:</p>
                    <p>:</p>
                    {/* {(order?.paymentType === "COD" || order?.paymentType === "bankTransfer" || order?.paymentType === "upi") && ( */}
                    <p>:</p>
                    {/* )} */}
                    {
                      !(
                        order?.paymentDetails?.paymentStatus === "COMPLETED" || order?.orderStatus === "CANCELLED" ||
                        (order?.paymentDetails?.paymentMethod === null ||
                          order?.paymentDetails?.paymentMethod === "NA" ||
                          order?.paymentDetails?.paymentMethod === "") &&
                        (order?.paymentType === null ||
                          order?.paymentType === "NA" ||
                          order?.paymentType === "")
                      ) && (
                        <p>:</p>
                      )}
                  </div>
                  <div className="ordersPage-div-p">
                    <p>{order?.paymentType ? order.paymentType : "NA"}</p>
                    <p> {order?.paymentDetails.paymentStatus ? order?.paymentDetails.paymentStatus : "NA"}</p>
                    <p><INRrupee />{order.couponApplied
                      ? ((order?.totalAfterDiscount || 0) + (order?.deliveryFee || 0)).toFixed(2)
                      : ((order?.totalDiscountedPrice || 0) + (order?.deliveryFee || 0)).toFixed(2)}</p>
                    <p><INRrupee />
                      {
                        order?.paymentDetails?.paymentStatus === "COMPLETED"
                          ? order?.couponApplied === true
                            ? ((order?.totalAfterDiscount || 0) + (order?.deliveryFee || 0)).toFixed(2) // Coupon applied
                            : ((order?.totalDiscountedPrice || 0) + (order?.deliveryFee || 0)).toFixed(2) // Coupon not applied
                          : (order?.paymentDetails?.amountReceived || 0).toFixed(2) // Payment not completed
                      }</p>

                    {
                      !(
                        order?.paymentDetails?.paymentStatus === "COMPLETED" || order?.orderStatus === "CANCELLED" ||
                        (order?.paymentDetails?.paymentMethod === null ||
                          order?.paymentDetails?.paymentMethod === "NA" ||
                          order?.paymentDetails?.paymentMethod === "") &&
                        (order?.paymentType === null ||
                          order?.paymentType === "NA" ||
                          order?.paymentType === "")
                      ) && (
                        <p>
                          <INRrupee />
                          {
                            order?.couponApplied === true
                              ? ((order?.totalAfterDiscount || 0) + (order?.deliveryFee || 0) - (order?.paymentDetails?.amountReceived || 0)).toFixed(2) // Coupon applied
                              : ((order?.totalDiscountedPrice || 0) + (order?.deliveryFee || 0) - (order?.paymentDetails?.amountReceived || 0)).toFixed(2) // Coupon not applied
                          }
                        </p>
                      )
                    }

                  </div>
                </div>
              </div>
              <Divider />
              <div className="row product-details mb-5">
                <div className="d-flex justify-content-between">
                  <div className="button-direction-orders d-flex justify-content-between">
                    <div className="d-flex align-content-center ordersPage-imageGap-mobile align-items-center gap-3">
                      <img
                        src={`${PRODUCT_BASE_URL}${firstItem?.imageUrl}`}
                        alt={firstItem?.title}
                        className="my-orders-product-img d-flex"
                      />
                      <div className="order-content">
                        <h5>{firstItem?.title}</h5>
                        <div className="d-flex gap-2 pt-1">
                          <Rate
                            disabled
                            allowHalf
                            value={firstItem?.numRatings}
                            style={{
                              display: "flex",
                              justifyContent: "start",
                              paddingBottom: "5px",
                            }}
                          />
                          <p style={{ fontSize: "12px", color: "#1b3a57" }}>
                            {firstItem?.numRatings}
                          </p>
                          <p style={{ fontSize: "12px", color: "#1b3a57" }}>
                            ({firstItem?.totalRatings})
                          </p>
                        </div>

                        <div className="d-flex gap-1 align-items-center justify-content-center">
                          <p>Quantity: {firstItem?.quantity}</p>
                          <span className="product-detail-discount">
                            {firstItem?.discountPercentage}% off
                          </span>
                        </div>
                        <div className="d-flex mt-2 align-items-center gap-1">
                          <p className="d-flex align-items-center"><INRrupee /> {firstItem?.discountedPrice}{" "}</p>
                          <span className="text-muted text-decoration-line-through d-flex align-items-center">
                            <INRrupee /> {firstItem?.price}
                          </span>
                          
                          {/* <span style={{backgroundColor:"#1b3a57",color:"#fff",padding:"5px",borderRadius:"45%",fontSize:"12px"}}>{firstItem.discountPercentage}%</span><br/> */}

                        </div>
                      </div>
                      {
                        order?.orderStatus === "PENDING" && order?.paymentDetails?.paymentStatus === "PENDING" && (
                          <div className="d-md-none d-flex  mt-0" >
                            <Dropdown overlay={menu(order)} trigger={['click']}>
                              <div className="three-dots">
                                <div onClick={() => setShowActions(!showActions)}>
                                  <HiDotsVertical style={{ display: 'flex' }} />
                                </div>
                              </div>

                            </Dropdown>


                          </div>
                        )
                      }

                    </div>
                    <div className="col span-4 d-flex align-items-center justify-content-end media-button-orderspage">
                      <button
                        type="button"
                        className="add-to-cart-button "
                        onClick={() => handleDetails(order)}
                      >
                        View Detail
                      </button>


                    </div>
                    {order?.orderStatus === "PENDING" && order?.paymentDetails?.paymentStatus === "PENDING" && (
                      <div className="orderspage-buttons-div ">
                        {order?.orderStatus === "PENDING" && order?.paymentDetails?.paymentStatus === "PENDING" && (
                          <Popconfirm
                            title="Are you sure you want to cancel this order?"
                            onConfirm={() => handleCancel(order.orderId)}
                            onCancel={() => message.info('Order cancellation aborted')}
                            okText="Yes"
                            cancelText="No"
                          >
                            <button
                              type="button"
                              className="ordersPage-cancel-button d-none d-md-flex justify-content-center"
                            >
                              Cancel Order
                            </button>
                          </Popconfirm>
                        )}


                        {order?.orderStatus === "PENDING" && order?.paymentDetails?.paymentStatus === "PENDING" && (
                          <div><button
                            type="button"
                            className="ordersPage-proceedPayment-button"
                            onClick={() => handleRedirect(order?.orderId)}
                          >
                            Proceed Payment
                          </button>
                          </div>
                        )}

                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

          </>

        </div>
      );
    });
  };


  return (
    <div className="container my-orders">
      <h4 className="md:mt-4">My Orders</h4>
      <Menu
        onClick={onClick}
        selectedKeys={[current]}
        mode="horizontal"
        items={items}

        className="my-orders-menu mt-4  "
      />
      {loading ? (
        <Spin size="large" className="loading-spinner mt-5" />
      ) : (
        <>
          {current === "active" && renderOrders(activeOrders)}
          {current === "cancelled" && renderOrders(cancelledOrders)}
          {current === "completed" && renderOrders(completedOrders)}
        </>
      )}
    </div>
  );
};

export default OrdersPage;
