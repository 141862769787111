import React, { useState, useEffect } from "react";
import "./ShoppingCart.css";
import { Radio, Modal, Button, message, Upload, Rate, Spin } from "antd";
import {
  CheckCircleOutlined,
  CloudUploadOutlined,
  DeleteOutlined,
  ArrowRightOutlined,
} from "@ant-design/icons";
import { useSelector } from "react-redux";
import Instance from "../../AxiosConfig";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import QRCode from "qrcode";
import { Navigate, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import INRrupee from "../Home/INRrupee";
import {PRODUCT_BASE_URL} from "../../globalConstant"

export const PaymentOptions = () => {
  const { Dragger } = Upload;
  const [paymentType, setPaymentType] = useState(null);
  const [loading, setLoading] = useState(false);
  const [receiptUploaded, setReceiptUploaded] = useState(false);
  const [receiptFiles, setReceiptFiles] = useState([]);
  const [upiQRCode, setUpiQRCode] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [addressData, setAddressData] = useState([]);
  const [previewVisible, setPreviewVisible] = useState(false); // Control modal visibility
  const [previewImage, setPreviewImage] = useState(""); // Track the image to preview
  const [previewIndex, setPreviewIndex] = useState(null); // to store the index of the previewed file
  const [isChecked, setIsChecked] = useState(true);

  const handleCheckboxChange = (e) => {
    setIsChecked(e.target.checked);
  };

  const navigate = useNavigate();
  const { orderId } = useParams();
  console.log("Received orderId:", orderId);
  const handlePreview = (file) => {
    if (!file.preview) {
      file.preview = URL.createObjectURL(file);
    }
    setPreviewImage(file.preview);
    setPreviewVisible(true);
  };

  const handleRemove = (file) => {
    setReceiptFiles((prevFiles) => {
      const updatedFiles = prevFiles.filter((item) => item.uid !== file.uid);
      if (updatedFiles.length === 0) {
        setReceiptUploaded(false);
      }
      return updatedFiles;
    });
  };

  const props = {
    name: 'file',
    multiple: true,
    accept: '.jpeg,.jpg,.png',
    fileList: receiptFiles,
    beforeUpload: (file) => {
      if (receiptFiles.length >= 3) {
        message.error('You can only upload up to 3 images.');
        return false;
      }
      const isJPGorPNG = file.type === 'image/jpeg' || file.type === 'image/png';
      if (!isJPGorPNG) {
        message.error('You can only upload JPEG or PNG files!');
        return false;
      }

      // Generate file preview URL
      file.preview = URL.createObjectURL(file);

      setReceiptFiles((prevFiles) => {
        const updatedFiles = [...prevFiles, file];
        if (updatedFiles.length > 0) {
          setReceiptUploaded(true);
        }
        return updatedFiles;
      });
      return false; // Prevent automatic upload
    },
  };


  // Function to handle the preview of an image



  const fetchOrderDetails = async () => {
    setLoading(true);
    try {
      const response = await Instance.get(`/api/v1/orders/${orderId}`);
      if (response.status === 200) {
        setAddressData(response.data);
        console.log("Fetched order details:", response.data);
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
      message.error(error?.response?.data?.message || "An error occurred");
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchOrderDetails();
  }, [orderId]);

  const [selectedOption, setSelectedOption] = useState(null);
  const [paymentConfirmed, setPaymentConfirmed] = useState(false);

  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
    setPaymentType(e.target.value);
    setPaymentConfirmed(false);
    if (e.target.value === "COD") {
      setReceiptUploaded(true);
    } else if (e.target.value === "upi") {
      generateUpiQRCode();
    }
  };
  const generateUpiQRCode = async () => {
    const upiId = "konakeerth@axl";
    const amount = addressData.totalPriceIncludeDeliveryFee;
    const upiString = `upi://pay?pa=${upiId}&pn=Your Name&am=${amount}&cu=INR`;

    try {
      const qrCodeDataUrl = await QRCode.toDataURL(upiString);
      setUpiQRCode(qrCodeDataUrl);
    } catch (error) {
      console.error("Error generating QR Code", error);
    }
  };
  const handleConfirmPayment = async () => {
    if (!isChecked) {
      message.error("Please agree to the terms and conditions.");
      return;
    }
    if (!selectedOption) {
      message.error("Please select a payment option.");
      return;
    }
    if (paymentType === "upi" || paymentType === "bankTransfer") {
      if (!receiptUploaded) {
        message.error("Please upload a receipt before confirming payment.");
        return;
      }
      try {
        const orderId = addressData.orderId;
        await handleUpiPayment(orderId, receiptFiles, paymentType);
      } catch (error) {
        console.error("Error uploading receipt:", error.message);
        message.error("Failed to upload receipt.");
        return;
      }
    } else if (paymentType === "COD") {
      handlePayment();
    }
  };

  const handleSuccessModalClose = () => {
    navigate("/profile/my-orders");
  };

  const handleShop = () => {
    navigate("/shop");
  };
  const handlePayment = async () => {
    const orderId = addressData.orderId;
    const token = localStorage.getItem("authToken");
    setLoading(true);
    try {
      const response = await Instance.post(
        `/api/v1/payment/${orderId}?paymentType=${paymentType}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200 || response.status === 201) {
        console.log("Payment Successful", response);
        if (response.data.payment_link_url) {
          window.location.href = response.data.payment_link_url;
        }
      }
    } catch (error) {
      console.error(
        "Error during payment:",
        error.response ? error.response.data : error.message
      );
      message.error(
        error.response ? error.response.data.message : "Unknown error occurred"
      );
    }finally{
      setLoading(false);
    }
  };

  const handleUpiPayment = async (orderId, receiptFiles, paymentType) => {
    const token = localStorage.getItem("authToken");
    const url = `api/v1/orders/upload-image/${orderId}?paymentType=${paymentType}`;
    const formData = new FormData();
    receiptFiles.forEach((file) => {
      formData.append("files", file);
      console.log("Appending file:", file);
    });
    for (let [key, value] of formData.entries()) {
      console.log(`${key}:`, value);
    }
    setLoading(true)

    try {
      const response = await Instance.put(url, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      });
      console.log("Upload successful:", response.data);
      setModalVisible(true);
      setLoading(false);
      return response.data;

    } catch (error) {
      console.error(
        "Error uploading receipt:",
        error.response ? error.response.data : error.message
      );
      throw error;
    }finally{
      setLoading(false);
    }
  };

  const groupedOrderItems = (addressData.orderItems || []).reduce(
    (acc, item) => {
      if (!acc[item.productId]) {
        acc[item.productId] = [];
      }
      acc[item.productId].push(item);
      return acc;
    },
    {}
  );

  const ProductCard = ({
    productId,
    image,
    name,
    rating,
    totalRatings,
    currentPrice,
    originalPrice,
    quantity: initialQuantity,
    showUnderline,
    onDelete,
    steelSize,
    isSteelProduct,
    discountedPercentage,
    quantities,
  }) => {
    const [quantity, setQuantity] = useState(initialQuantity);
    const [isExpanded, setIsExpanded] = useState(false);

    const handleDelete = () => {
      onDelete(productId);
    };

    const toggleExpand = () => {
      setIsExpanded(!isExpanded);
    };

    const calculateTotalDiscountedPrice = () => {
      if (isSteelProduct) {
        return quantities?.reduce(
          (total, item) => total + item.discountedPrice,
          0
        );
      }
      return currentPrice;
    };

    const calculateOriginalPrice = () => {
      if (isSteelProduct) {
        return quantities?.reduce((total, item) => total + item.price, 0);
      }
      return originalPrice;
    };
    const calculateDiscountPercentage = () => {
      if (isSteelProduct && quantities.length > 0) {
        const totalPrice = quantities.reduce(
          (total, item) => total + item.price,
          0
        );
        const totalDiscountedPrice = quantities.reduce(
          (total, item) => total + item.discountedPrice,
          0
        );
        return Math.round(((totalPrice - totalDiscountedPrice) / totalPrice) * 100);
      }
      return discountedPercentage; // Use the provided discount percentage for non-steel products.
    };

    return (
      <div className={`row left-main-cart ${showUnderline ? "underline" : ""}`}>
        <div className="col-md-3">
          <img src={image} className="shop-img-cart" alt="" />
        </div>
        <div className="col-md-6 ms-md-4 shopping-cart-card-details">
          <h5>{name}</h5>
          <div className="d-flex gap-2 pt-1">
            <Rate
              disabled
              allowHalf
              value={rating}
              style={{
                display: "flex",
                justifyContent: "start",
                paddingBottom: "5px",
              }}
            />
            <p style={{ fontSize: "12px", color: "#1b3a57" }}>{rating}</p>
            <p style={{ fontSize: "12px", color: "#1b3a57" }}>
              ({totalRatings})
            </p>
          </div>
          {!isSteelProduct && (
            <p style={{ paddingBottom: "5px" }}>Quantity : {quantity}</p>
          )}
          <span className="product-current-price">
            ₹ {calculateTotalDiscountedPrice()}
          </span>
          <span className="product-original-price">
            ₹ {calculateOriginalPrice()}
          </span>
          <span
            style={{
              backgroundColor: "#1b3a57",
              color: "#fff",
              padding: "6px",
              borderRadius: "45%",
              fontSize: "12px",
            }}
          >
            {calculateDiscountPercentage()}% off
          </span>
          <br />
          {isSteelProduct && (
            <button
              className="view-more-button"
              style={{
                backgroundColor: "#fff",
                border: "none",
                color: "#1b3a57",
              }}
              onClick={toggleExpand}
            >
              {isExpanded ? "View Less" : "View More"}
              {isExpanded ? <IoIosArrowUp /> : <IoIosArrowDown />}
            </button>
          )}
        </div>
        {isExpanded && isSteelProduct && (
          <div className="steel-product-details">
            <table className="table">
              <thead>
                <tr>
                  <th>Steel Size</th>
                  <th>Quantity</th>
                  <th>Price</th>
                </tr>
              </thead>
              <tbody>
                {quantities.map((item) => (
                  <tr key={item.orderItemId}>
                    <td>{item.steelSize}</td>
                    <td>{item.quantity}</td>
                    <td>₹ {item.discountedPrice}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    );
  };

  const products = Object.keys(groupedOrderItems).map((productId) => {
    const items = groupedOrderItems[productId];
    const firstItem = items[0];

    return {
      image: `${PRODUCT_BASE_URL}${firstItem.imageUrl}`,
      name: firstItem.title,
      quantity: firstItem.quantity,
      rating: firstItem.numRatings,
      currentPrice: items.reduce(
        (total, item) => total + item.discountedPrice,
        0
      ),
      originalPrice: items.reduce((total, item) => total + item.price, 0),
      totalRatings: firstItem.totalRatings,
      discountedPercentage: firstItem.discountPercentage,
      isSteelProduct: firstItem.steelProduct,
      quantities: items,
    };
  });

  return (
    <div className="container payment-page-container">
      <div className="row">
        <div className="col-lg-6">
          {/* {loading ? (
            <Spin size="large" className="loading-spinner mt-5" />
          ) : ( */}
          <div className="card payment-option-product-div">
            {loading ? (
              <Spin size="large" className="loading-spinner" style={{ marginTop: '200px' }} />
            ) : (
              <>
                {products.map((product, index) => (
                  <ProductCard
                    key={index}
                    {...product}
                    showUnderline={index !== products.length - 1}
                  />
                ))}
              </>
            )}


          </div>
          {/* )} */}

          <div className="card p-2 order-summary-card mt-4">
            <h5 className="text-align-left d-flex">Order Summary</h5>
            <div className="d-flex justify-content-between sub-total-price px-4">
              <div className="sub-discount">
                <p>Sub Total</p>
                <p>Discount ({addressData.discountPercentage}%)</p>
                {addressData.couponApplied && (
                  <p style={{ color: "#54C104" }}>Coupon Savings</p>
                )}
                <p style={{ fontSize: "13px", color: "gray" }}>
                  {addressData.orderItems?.some(item => item.steelProduct === true) && (
                    <p className="mt-2"> GST applied to the Steel</p>
                  )}
                  {addressData.orderItems?.some(item => item.steelProduct === false) && (
                    <p className="mt-2"> GST applied to the Cement</p>
                  )}
                </p>
              </div>
              <div className="total-price">
                <p>₹ {(addressData.totalPrice ?? 0).toFixed(2)}</p>
                <p style={{ marginLeft: "-8px" }}>-₹ {(addressData.discount ?? 0).toFixed(2)}</p>
                {addressData.couponApplied && (
                  <p
                    style={{
                      color: "#54C104",
                      marginLeft: "-8px",
                      fontWeight: "200",
                    }}
                  >
                    {addressData.couponType === "FREE_SHIPPING"
                      ? "Free Delivery"
                      : `-₹ ${(addressData.couponDiscountedPrice ?? 0).toFixed(
                        2
                      )}`}
                    {/* -₹{addressData.couponDiscountedPrice} */}
                  </p>
                )}
                <p>
                  {addressData.orderItems?.some(item => item.steelProduct === true) && (
                    <p> 18% </p>
                  )}
                  {addressData.orderItems?.some(item => item.steelProduct === false) && (
                    <p> 28%</p>
                  )}
                </p>
              </div>
            </div>
            <div className="d-flex justify-content-between pt-4 total-price px-4">
              <div>
                <p>Total Amount</p>
                <p style={{ fontWeight: "400" }}>Delivery Fee</p>
              </div>
              <div>
                <p>
                  ₹
                  {addressData.couponApplied
                    ? (addressData.totalAfterDiscount ?? 0).toFixed(2)
                    : (addressData.totalDiscountedPrice ?? 0).toFixed(2)}
                </p>
                <p
                  style={{
                    color: "#6f7274",
                    textDecoration:
                      addressData.couponType === "FREE_SHIPPING"
                        ? "line-through"
                        : "none",
                    textDecorationColor: addressData.couponType === 'FREE_SHIPPING' ? '#6f7274' : 'none',
                    textDecorationThickness: addressData.couponType === 'FREE_SHIPPING' ? '2px' : 'auto',
                  }}
                >
                  ₹ {(addressData.deliveryFee ?? 0).toFixed(2)}
                </p>
              </div>
            </div>
            <div className="straight-line-border my-1"></div>

            <div className="d-flex justify-content-between pt-2 total-price px-4">
              <div>
                <p>Amount Payable</p>
              </div>
              <div>
                <p>
                  ₹ {(addressData.totalPriceIncludeDeliveryFee ?? 0).toFixed(2)}

                </p>
              </div>
            </div>
          </div>
        </div>

        <div
          className="col-lg-5 cash-on-delivery-mob"
          style={{ marginLeft: "20px" }}
        >
          <div className="payment-form mt-4">
            <h3>Payment Options</h3>
            <div className="payment-option justify-content-start align-content-end">
              <Radio
                value="COD"
                checked={selectedOption === "COD"}
                onChange={handleOptionChange}
                className="payment-radio"
                disabled={addressData.couponType === "FREE_SHIPPING"}
              >
                Cash On Delivery
              </Radio>
              <h6>Pay With cash upon delivery</h6>

              {addressData.couponType === "FREE_SHIPPING" && (
                <p style={{ color: "red", marginTop: "10px" }}>
                  For free shipping cash on delivery not available
                </p>
              )}

              {selectedOption === "COD" &&
                !paymentConfirmed &&
                addressData.couponType !== "FREE_SHIPPING" && (
                  <div className="mt-2 ps-4 payment-options-description">
                    <div className="d-flex gap-4">
                      <p style={{ color: "red" }}>Minimum Amount delivery</p>
                      <p>₹ {(addressData.deliveryFee ?? 0).toFixed(2)}</p>
                    </div>
                  </div>
                )}
            </div>

            <div>
              <Radio
                value="upi"
                checked={selectedOption === "upi"}
                onChange={handleOptionChange}
                className="payment-radio"
                disabled={addressData.totalPriceIncludeDeliveryFee >= 100000}
              >
                UPI QR Code
              </Radio>
              <h6>Make payment through upi</h6>
              {addressData.totalPriceIncludeDeliveryFee >= 100000 && (
                <p style={{ color: 'red', marginLeft:"22px", fontSize:"14px" }}>
                  <span >
                    UPI not available for amount over <span style={{ color: 'red' }}>&#8377;</span> 1 Lakh.
                  </span>
                </p>
              )}
            </div>
            {selectedOption === "upi" && (
              <div className="col-12 mt-2 d-flex justify-content-center">
                {upiQRCode ? (
                  <img
                    src={upiQRCode}
                    alt="UPI QR Code"
                    style={{ width: "150px", height: "150px" }}
                  />
                ) : (
                  <Spin />
                )}
              </div>
            )}
            <div className="payment-option">
              <Radio
                value="bankTransfer"
                checked={selectedOption === "bankTransfer"}
                onChange={handleOptionChange}
                className="payment-radio"
              >
                Direct Bank Transfer
              </Radio>
              <h6>Make payment directly through bank account.</h6>
              {selectedOption === "bankTransfer" && (
                <div className="payment-option">
                  <div className="mt-2">
                    <div className="bank-details-form-group">
                      <div className="d-flex gap-2 paymentDeatils-form-input">
                        <p style={{ fontWeight: 500 }}>Account Holder Name:</p>
                        <p>Sri Krishna Enterprises</p>
                      </div>
                      <div className="d-flex gap-2 paymentDeatils-form-input">
                        <p style={{ fontWeight: 500 }}>Account Number:</p>

                        <p>0677000101006301</p>
                      </div>
                      <div className="d-flex gap-2 paymentDeatils-form-input">
                        <p style={{ fontWeight: 500 }}>Bank Name:</p>

                        <p>Karnataka Bank</p>
                      </div>
                      <div className="d-flex gap-2 paymentDeatils-form-input">
                        <p style={{ fontWeight: 500 }}>Branch:</p>

                        <p>Wilson Garden</p>
                      </div>
                      <div className="d-flex gap-2 paymentDeatils-form-input">
                        <p style={{ fontWeight: 500 }}>IFSC Code:</p>

                        <p>KARB0000067</p>
                      </div>
                      <div className="d-flex gap-2 paymentDeatils-form-input">
                        <p style={{ fontWeight: 500 }}>GST:</p>

                        <p>29ACGPV6137M1ZT</p>
                      </div>
                    </div>
                  </div>
                  {/* )} */}
                </div>
              )}
            </div>
            {paymentType === "upi" || paymentType === "bankTransfer" ? (
              <div className="mt-2 mb-4 payment-options-description">
                <h5 style={{ color: "#1b3a57" }}>
                  Upload a photo of Transaction Recipient
                </h5>
                <div className="mt-2" style={{ width: '80%', margin: 'auto' }}>
                  <Dragger {...props} className="m-4 payment-file-upload">
                    <p className="ant-upload-drag-icon">
                      <CloudUploadOutlined />
                    </p>
                    <p className="ant-upload-text">Drag & drop files or <a href="#">Browse</a></p>
                    <p className="ant-upload-hint">Supported formats: JPEG, PNG</p>
                  </Dragger>


                  {/* Render file list manually */}
                  <div className="ant-upload-list d-flex justify-content-between">
                    {receiptFiles.map((file) => (
                      <div key={file.uid} className="ant-upload-list-item justify-content-between">
                        <div>
                          <img
                            onClick={() => handlePreview(file)}
                            src={file.preview || URL.createObjectURL(file.originFileObj)}
                            alt={file.name}
                            className="preview-image"
                            style={{ cursor: 'pointer', display: 'flex', marginBottom: '4px', maxWidth: '100px', maxHeight: '100px' }}
                          />
                        </div>

                        <div className="preview-image-delete">
                          <span
                            onClick={() => handlePreview(file)}
                            style={{ cursor: 'pointer', fontSize: '10px', marginBottom: '4px' }}
                          >
                            {file.name}
                          </span>
                          <Button
                            icon={<DeleteOutlined style={{ width: '10px', height: '10px' }} />}
                            onClick={() => handleRemove(file)}
                            size="10px"
                            style={{ border: 'none', width: '10px', height: '10px', color: '#000000' }}
                          >

                          </Button>
                        </div>
                      </div>
                    ))}
                  </div>

                  {/* Modal to preview the image */}
                  <Modal
                    visible={previewVisible}
                    footer={null}
                    onCancel={() => setPreviewVisible(false)}
                  >
                    <img alt="Preview" style={{ width: '100%' }} src={previewImage} />
                  </Modal>
                </div>
                {/* <p className="mt-2">Uploaded</p>
              <div className="d-flex justify-content-between pdf-document">
                <p className="mb-0">document-name.PDF</p>
                <DeleteOutlined style={{ color: "red" }} />
              </div> */}
              </div>
            ) : null}

            <h5>Review And Place Order</h5>
            <p>
              Please review the order details and payment details before
              proceeding to confirm your order{" "}
            </p>
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                id="gridCheck"
                defaultChecked
                onChange={handleCheckboxChange}
              />
              <label className="form-check-label" htmlFor="gridCheck">
                I agree to the <a href="/terms-and-conditions">Terms & conditions</a>,{" "} 
                <a href="/cancellation-and-refund">Cancellation & Refund</a>,{" "}
                <a href="/shipping-info">Shipping Info</a>, {" "}
                <a href="/privacy-policy">Privacy policy</a> &{" "}
                
              </label>
            </div>


            <button
              type="primary"
              block
              className="mt-3 faq-get-in-touch-button"
              style={{ width: "100%" }}
              onClick={handleConfirmPayment}
            >
              {loading ? (
                <Spin size="small" />
              ) : (
                "Confirm Payment"
              )}
            </button>


          </div>
        </div>
      </div>
      <Modal
        visible={modalVisible}
        footer={null}
        onCancel={handleSuccessModalClose}
      >
        <div className="d-flex justify-content-center flex-column forgot-password-div">
          <CheckCircleOutlined
            style={{ color: "green", fontSize: "70px", marginBottom: "20px" }}
            className="d-flex justify-content-center"
          />
          <h3 className="text-center"> Your Order has beeen placed </h3>
          <h5 style={{ color: "#54595e", textAlign: "center" }}>Thank You</h5>
          <div className="d-flex gap-4 justify-content-center">
            <Button
              key="submit"
              type="primary"
              className="continue-button"
              onClick={handleShop}
            >
              Continue Shopping
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};
