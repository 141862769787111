import React, { useState, useEffect } from "react";
import "./details.css";
import Instance from "../../../AxiosConfig";
import { message } from "antd";
import { useNavigate, useLocation } from "react-router-dom";
import { getLocationFromAddress } from "../../../awsService";
export const EditAddress = () => {
  //   const [addressData, setaddressData] = useState({
  //     firstName: "",
  //     lastName: "",
  //     streetAddress: "",
  //     address: "",
  //     city: "",
  //     state: "",
  //     country: "",
  //     zipCode: "",
  //     mobile: "",
  //     setAsDefaultBilling: false,
  //   });
  const [addressData, setAddressData] = useState({
    firstName: "",
    lastName: "",
    streetAddress: "",
    address: "",
    state: "",
    city: "",
    country: "",
    zipCode: "",
    gstNumber: "",
    mobile: "",
  });
  const [loading, setLoading] = useState(false);
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [distance, setDistance] = useState(null);
  const [nearestStore, setNearestStore] = useState(null);

  const navigate = useNavigate()
  const handleBack = () => {
    navigate('/profile/details')
  }
  const [errors, setErrors] = useState({});
  const location = useLocation();
  const { address } = location.state || {};

  useEffect(() => {
    if (address) {
      setAddressData(address);
    }
  }, [address]);

  //   const handleChange = (e) => {
  //     const { name, value, type, checked } = e.target;
  //     setaddressData({
  //       ...addressData,
  //       [name]: type === "checkbox" ? checked : value,
  //     });
  //   };

  const fetchCoordinates = async () => {
    try {
      const response = await Instance.get("/api/v1/store-info/all", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
      });
      if (response.status === 200) {
        console.log("Store coordinates:", response.data);
        calculateStoreDistances(response.data);
      }
    } catch (error) {
      console.error("Error fetching store data:", error);
      message.error(error?.response?.data?.message || "An error occurred");
    }
  };



  const calculateStoreDistances = (stores) => {
    if (!latitude || !longitude) return;

    const distances = stores.map((store) => {
      const distance = Math.ceil(calculateDistance(latitude, longitude, store.latitude, store.longitude) * 10) / 10;
      return { ...store, distance };
    });

    console.log("Distances to all stores:", distances);
    const nearest = distances.reduce((prev, curr) => (prev.distance < curr.distance ? prev : curr));
    setNearestStore(nearest);
    setDistance(nearest.distance);

    console.log(`Nearest Store: ${nearest.storeName}, Distance: ${nearest.distance} km`);
  };

  const handleAddressChange = async () => {
    const { streetAddress, address, city, state, country, zipCode } = addressData;
    const fullAddress = `${streetAddress}, ${address}, ${city}, ${state}, ${country}, ${zipCode}`;

    try {
      const response = await getLocationFromAddress(fullAddress);
      if (response && response.Results && response.Results.length > 0) {
        const position = response.Results[0].Place.Geometry.Point;
        const addressLatitude = position[1];
        const addressLongitude = position[0];

        setLatitude(addressLatitude);
        setLongitude(addressLongitude);
        fetchCoordinates();
        setAddressData((prevFormData) => ({
          ...prevFormData,
          latitude: latitude,
          longitude: longitude,
          // distance: distance,
        }));

        console.log(`Latitude: ${latitude}, Longitude: ${longitude}, Distance: ${distance} km`);
        console.log("ADDRESS DATA to update", addressData)
      } else {
        message.error("Address not found");
      }
    } catch (error) {
      message.error("An error occurred while fetching location");
    }
  };
  const calculateDistance = (lat1, lon1, lat2, lon2) => {
    const toRadians = (degree) => (degree * Math.PI) / 180;
    const R = 6371;
    const dLat = toRadians(lat2 - lat1);
    const dLon = toRadians(lon2 - lon1);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(toRadians(lat1)) * Math.cos(toRadians(lat2)) *
      Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return R * c;
  };

  useEffect(() => {
    if (latitude && longitude) {
      fetchCoordinates();
    }
  }, [latitude, longitude]);
  useEffect(() => {
    if (
      addressData.streetAddress &&
      addressData.city &&
      addressData.state &&
      addressData.country &&
      addressData.zipCode
    ) {
      handleAddressChange();
    }
  }, [
    addressData.streetAddress,
    addressData.city,
    addressData.state,
    addressData.country,
    addressData.zipCode,
  ]);

  const validateForm = () => {
    const namePattern = /^[A-Za-z\s]*$/;
    const mobilePattern = /^[5-9]{1}[0-9]{9}$/;
    const gstPattern = /^[0-3][0-9][A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;
    const validCountries = new Set([
      "united states",
      "canada",
      "india",
      "australia",
      "ind",
    ]);

    let isValid = true;
    const newErrors = {};

    if (!addressData.firstName) {
      newErrors.firstName = "First name is required.";
      isValid = false;
    } else if (!namePattern.test(addressData.firstName.trim())) {
      newErrors.firstName = "First name can only contain letters and spaces.";
      isValid = false;
    } else {
      const lengthWithoutSpaces = addressData.firstName.trim().replace(/\s+/g, "").length;
      if (lengthWithoutSpaces < 3 || lengthWithoutSpaces > 15) {
        newErrors.firstName = "First name must be between 3 and 15 letters.";
        isValid = false;
      }
    }

    if (addressData.lastName) {
      const lengthWithoutSpaces = addressData.lastName.trim().replace(/\s+/g, "").length;
      if (!namePattern.test(addressData.lastName.trim())) {
        newErrors.lastName = "Last name can only contain letters and spaces.";
        isValid = false;
      } else if (lengthWithoutSpaces < 1 || lengthWithoutSpaces > 15) {
        newErrors.lastName = "Last name must be between 1 and 15 letters.";
        isValid = false;
      }
    }

    if (!addressData.mobile) {
      newErrors.mobile = "Mobile number is required.";
      isValid = false;
    } else if (!mobilePattern.test(addressData.mobile)) {
      newErrors.mobile = "Mobile number must be 10 digits and cannot start with 0-4.";
      isValid = false;
    }

    if (!addressData.streetAddress.trim()) {
      newErrors.streetAddress = "Address Line 1 is required.";
      isValid = false;
    }

    if (!addressData.city.trim()) {
      newErrors.city = "City is required.";
      isValid = false;
    } else if (!namePattern.test(addressData.city.trim())) {
      newErrors.city = "City can only contain letters and spaces.";
      isValid = false;
    }

    if (!addressData.state.trim()) {
      newErrors.state = "State is required.";
      isValid = false;
    } else if (!namePattern.test(addressData.state.trim())) {
      newErrors.state = "State can only contain letters and spaces.";
      isValid = false;
    }

    if (!addressData.zipCode.trim()) {
      newErrors.zipCode = "Zip Code is required.";
      isValid = false;
    } else if (!/^[0-9]{6}$/.test(addressData.zipCode.trim())) {
      newErrors.zipCode = "Zip Code must be exactly 6 digits.";
      isValid = false;
    }

    const country = addressData.country.trim().toLowerCase();
    if (!country) {
      newErrors.country = "Country is required.";
      isValid = false;
    } else if (!validCountries.has(country)) {
      newErrors.country = "Please enter a valid country.";
      isValid = false;
    }

    if (addressData.gstNumber) {
      const gstNumber = addressData.gstNumber.toUpperCase();
      if (!gstPattern.test(gstNumber)) {
        newErrors.gstNumber = "Invalid GST number. Please enter a valid 15-character GST number.";
        isValid = false;
      }
    }

    setErrors(newErrors);
    return isValid;
  };


  const updateAddress = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    if (!latitude || !longitude) {
      const locationData = await handleAddressChange();

      if (locationData) {
        setLatitude(locationData.latitude);
        setLongitude(locationData.longitude);
        // setDistance(locationData.distance);
      } else {
        message.error("Unable to retrieve location. Please verify the address and try again.");
        return;
      }
      console.log("ADDRESS DATA to update", addressData)
    }


    try {
      console.log("ADDRESS DATA to update", addressData)
      const response = await Instance.put(`/api/v1/address/${address.addressId}`, addressData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
      });
      if (response.status === 200) {
        message.success("Address updated successfully");
        navigate("/profile/details");
        console.log("RESPONSE AFTER UPDATE", response.data)
      }
    } catch (error) {
      console.error("Error updating address:", error);
      message.error(error?.response?.data?.message || "An error occurred");
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    let error = "";
    let processedValue = value;

    const validCountries = new Set([
      "united states",
      "canada",
      "india",
      "australia",
      "ind",
    ]);

    if (name === "firstName" || name === "lastName") {
      processedValue = value.replace(/[0-9]/g, "");

      if (!/^[a-zA-Z\s]*$/.test(processedValue)) {
        error = "Name can only contain letters and spaces";
      } else {
        const lengthWithoutSpaces = processedValue.replace(/\s+/g, "").length;

        if (name === "firstName") {
          if (lengthWithoutSpaces < 3 || lengthWithoutSpaces > 15) {
            error = "First name must be between 3 and 15 letters";
          }
        }
        // else if (name === "lastName") {
        //   if (lengthWithoutSpaces > 1 && lengthWithoutSpaces > 15) {
        //     error = "Last name must be between 1 and 15 letters";
        //   }
        // }
      }
    } else if (name === "mobile") {
      processedValue = value.slice(0, 10);

      if (!/^[5-9]{1}[0-9]{9}$/.test(processedValue)) {
        error = "Mobile number must be 10 digits and should not starts with 0-4.";
      }
    } else if (name === "city" || name === "state" || name === "country") {
      processedValue = value.trim().toLowerCase();

      if (!/^[a-zA-Z\s]*$/.test(processedValue)) {
        error = `${name.charAt(0).toUpperCase() + name.slice(1)} can only contain letters and spaces`;
      } else if (!processedValue) {
        error = `${name.charAt(0).toUpperCase() + name.slice(1)} is required.`;
      } else if (name === "country" && !validCountries.has(processedValue)) {
        error = "Please enter a valid country.";
      }
    } else if (name === "zipCode") {
      processedValue = value.replace(/[^0-9]/g, "").slice(0, 6);

      if (!processedValue.trim()) {
        error = "ZipCode is required.";
      } else if (processedValue.length !== 6) {
        error = "ZipCode must be exactly 6 digits.";
      }
    } else if (name === "streetAddress") {
      if (!value.trim()) {
        error = "Address Line 1 is required.";
      }
    } else if (name === "gstNumber") {
      const gstPattern = /^[0-3][0-9][A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;
      processedValue = value.toUpperCase();
      if (processedValue &&
        (!gstPattern.test(processedValue) || (processedValue.length < 15 && processedValue.length > 0))) {
        error = "Invalid GST number. Please enter a valid 15-character GST number.";
      }
    }

    setAddressData((prevData) => ({
      ...prevData,
      [name]: processedValue,
    }));

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: error,
    }));
  };



  return (
    <div className="container add-address-container mb-5">
      {/* <h2>Current Location</h2>
      <div className="d-flex gap-2">
        <svg
          width="32"
          height="33"
          viewBox="0 0 32 33"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M24.0026 24.5C25.6626 25.0653 26.6693 25.8093 26.6693 26.624C26.6693 28.3973 21.8933 29.8333 16.0026 29.8333C10.1119 29.8333 5.33594 28.396 5.33594 26.624C5.33594 25.8093 6.3426 25.0653 8.0026 24.5M20.0026 13.1667C20.0026 14.2275 19.5812 15.2449 18.831 15.9951C18.0809 16.7452 17.0635 17.1667 16.0026 17.1667C14.9417 17.1667 13.9243 16.7452 13.1742 15.9951C12.424 15.2449 12.0026 14.2275 12.0026 13.1667C12.0026 12.1058 12.424 11.0884 13.1742 10.3382C13.9243 9.58809 14.9417 9.16666 16.0026 9.16666C17.0635 9.16666 18.0809 9.58809 18.831 10.3382C19.5812 11.0884 20.0026 12.1058 20.0026 13.1667Z"
            stroke="#1B3A57"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M16 3.16666C21.412 3.16666 26 7.73733 26 13.2827C26 18.916 21.3373 22.8693 17.0307 25.5573C16.7171 25.7377 16.3617 25.8327 16 25.8327C15.6383 25.8327 15.2829 25.7377 14.9693 25.5573C10.6707 22.8427 6 18.936 6 13.2827C6 7.73733 10.588 3.16666 16 3.16666Z"
            stroke="#1B3A57"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        <div>
          <h3>Bangalore</h3>
          <span>BTM Layout 2nd cross</span>
        </div>
      </div> */}

      <h2 className="mt-4">Edit Address</h2>
      <form className="row g-3" onSubmit={updateAddress}>
        <div className="col-md-6">
          <input
            type="text"
            className="form-control add-address-form"
            placeholder="First Name"
            name="firstName"
            value={addressData.firstName}
            onChange={handleInputChange}
          />
          {errors.firstName && <p className="error">{errors.firstName}</p>}
        </div>
        <div className="col-md-6">
          <input
            type="text"
            className="form-control add-address-form"
            placeholder="Last Name"
            name="lastName"
            value={addressData.lastName}
            onChange={handleInputChange}

          />
          {errors.lastName && <p className="error">{errors.lastName}</p>}
        </div>
        <div className="col-12">
          <input
            type="text"
            className="form-control add-address-form"
            id="inputAddress"
            placeholder="Address Line-1"
            name="streetAddress"
            value={addressData.streetAddress}
            onChange={handleInputChange}
          />
          {errors.streetAddress && <p className="error">{errors.streetAddress}</p>}
        </div>
        <div className="col-12">
          <input
            type="text"
            className="form-control add-address-form"
            id="inputAddress2"
            placeholder="Address Line-2"
            name="address"
            value={addressData.address}
            onChange={handleInputChange}
          />
          {errors.address && <p className="error">{errors.address}</p>}
        </div>
        <div className="col-md-6">
          <input
            type="text"
            className="form-control add-address-form"
            id="inputCity"
            placeholder="City"
            name="city"
            value={addressData.city}
            onChange={handleInputChange}
          />
          {errors.city && <p className="error">{errors.city}</p>}
        </div>
        <div className="col-md-6">
          {/* <p style={{marginBottom:"0px"}}>Optional</p> */}
          <input
            type="text"
            className={`form-control add-address-form`}
            id="gstNumber"
            placeholder="Enter GST number (Optional)"
            name="gstNumber"
            value={addressData.gstNumber}
            maxLength="15"
            onChange={handleInputChange}
          />
          {errors.gstNumber && <p className="error">{errors.gstNumber}</p>}
        </div>
        <div className="col-md-6">
          <input
            type="text"
            className="form-control add-address-form"
            id="inputState"
            placeholder="State"
            name="state"
            value={addressData.state}
            onChange={handleInputChange}
          />
          {errors.state && <p className="error">{errors.state}</p>}
        </div>
        <div className="col-md-6">
          <input
            type="text"
            className="form-control add-address-form"
            name="country"
            placeholder="Country"
            value={addressData.country}
            onChange={handleInputChange}
          />
          {errors.country && <p className="error">{errors.country}</p>}
        </div>
        <div className="col-md-6">
          <input
            type="text"
            className="form-control add-address-form"
            name="zipCode"
            value={addressData.zipCode}
            onChange={handleInputChange}
            placeholder="Zip Code"

          />
          {errors.zipCode && <p className="error">{errors.zipCode}</p>}
        </div>
        <div className="col-md-6">
          <input
            type="text"
            className="form-control add-address-form"
            name="mobile"
            value={addressData.mobile}
            onChange={handleInputChange}
            placeholder="Enter phone number"
            onInput={(e) => e.target.value = e.target.value.replace(/[^0-9]/g, '')}

          />
          {errors.mobile && <p className="error">{errors.mobile}</p>}
        </div>
        {/* <div className="form-check" style={{ textAlign: "start" }}>
          <input
            className="form-check-input"
            type="checkbox"
            id="setAsDefaultBilling"
            name="setAsDefaultBilling"
          checked={addressData.setAsDefaultBilling}
          onChange={handleChange}
          />
          <label className="form-check-label" htmlFor="setAsDefaultBilling">
            Set as default billing address
          </label>
        </div> */}
        <div className="d-flex gap-2">
          <button className="save-address-button" type="submit">
            Update
          </button>
          <button className="cancel-address-button" onClick={handleBack}>
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
};
